import React, { Component } from 'react';
import { Link }
    from 'react-router-dom';
import { withTranslation }
    from 'react-i18next';

import { connect }
    from 'react-redux';
import Header from '../../common/Header';

import PersonalLezarLayout from './personalLezarLayout';
import { getViewLazer } from '../../actions/lezarActions';

import { Table, Container, Card, Button, Col, Row } from 'react-bootstrap';



import Spinner from '../../common/Spinner';

class ViewLezar extends Component {

    constructor(props) {

        super(props);
        this.state = {

            content: ''


        }


    }

    componentDidMount() {

        this.props.getViewLazer(this.props.match.params.product_id, this.props.match.params.account_number, this.props.match.params.user_id);
    }
    componentWillReceiveProps(nextprops) {


        let { view_lazer, loading } = nextprops.lazer;
        let content = '';
        if (loading === false && view_lazer !== null) {
            content = <PersonalLezarLayout data={view_lazer.data} user={view_lazer.user[0]} />;
            this.setState({ content: content,loading:loading });
        }
    }

    render() {
        return (
            <Col lg={12}>
                <Header />
                <Card bg="custom-info" >
                    <Card.Header>


                    </Card.Header>
                    <Card.Body>
                        {this.state.loading && <Spinner />}

                       {this.state.content}


                    </Card.Body>
                </Card>
            </Col>
        )

    }

}

const mapStateToProps = state => ({
    lazer: state.lezar
});


export default withTranslation()(connect(mapStateToProps, { getViewLazer })(ViewLezar));