/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import React, { Component }
  from 'react';
import { Link }
  from 'react-router-dom';

import { connect }
  from 'react-redux';
import { withTranslation }
  from 'react-i18next';

import Header from '../../common/Header';
import { intCollection,CollectionByMonth } from '../../actions/collectionActions';

import LocalizedMessage from '../../common/LocalizedMessage';
import { Form, Row, Col, Table, Container, Breadcrumb, Card, Button, Input, InputGroup, FormControl }
  from 'react-bootstrap';

import SaInput from '../../common/SaInput';

import SaDateInput from '../../common/SaDateInput';

import SaButtonInput from '../../common/SaButtonInput';
let EnglishToBanglsNumber = require('../../common/EnglishToBanglsNumber');

let EnglishToBanglamonth = require('../../common/EnglishToBanglamonth');

class Collection extends Component {

  constructor() {
    super();



    this.state = {
      next: '',
      all_collection: []

    }





  }

  componentDidMount() {
    this.props.intCollection();
  }
  componentWillReceiveProps(nextProps) {

    let { int_collection,collection_by_month, loading } = nextProps.int_collection;

    if (loading === false && int_collection && int_collection !== null) {
      this.setState({ all_collection: int_collection });
    }

    if(loading === false && collection_by_month && collection_by_month !==null){
      this.setState({ all_collection: collection_by_month });
    }




  }

  renderCollection = () => {
    var all_collection = this.state.all_collection;

    var options = {
      year: "numeric",
      month: "2-digit",
      day: "numeric"
    };

    var arr = [];
    Object.keys(all_collection).forEach(function (key) {

      var date = all_collection[key][0]['date'].split("-");

    //  console.log(date);

      var n = new Date(date[0] + '-' + date[1] + '-' + date[2]);
      // console.log(all_collection[key][0]['users']['name']);
      arr.push(<tr key={key}>
        <td>{n.toLocaleString("bn-BD", options)}</td>
        <td>{EnglishToBanglsNumber(all_collection[key][0]['sheet_no'])}</td>
        <td>{all_collection[key][0]['users']['name']}</td>
        <td><Link to={'/collection/'+all_collection[key][0]['sheet_no']} className="btn btn-outline-info btn-block">দেখুন </Link></td>
      </tr>);
    });

    return arr;



  }
  ShowMonths = () => {

    let html = [];

    for (var x = 1; x < 13; x++) {
      html.push(<option key={x} value={x}>{EnglishToBanglamonth(x)}</option>);

    }

    return html;

  }
  handleMonthChange =(event) =>{
    this.setState({ loading: true });
    if(event.target.value==='all'){
      this.props.intCollection();
    }else{
      let data = {
        'item': 'month',
        'value': event.target.value
      }
  
  
      this.props.CollectionByMonth(data);
    }

    
  }


  render() {
    return (
      <Col lg={12}>
        <Header />

        <Container className="mt-5">
          <Row>
            <Col lg={6} md={6}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <Button variant="secondary">তালিকা অনুসন্ধান </Button>
                </InputGroup.Prepend>
                <FormControl aria-label="Amount (to the nearest dollar)" />
                <InputGroup.Append>
                  <Button variant="success">+</Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col lg={3} md={3}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <Button variant="secondary">মাস </Button>
                </InputGroup.Prepend>
                <Form.Control className="custome-select" as="select"  onChange={this.handleMonthChange}>
                  <option value="all"> </option>
                  {this.ShowMonths()}
                </Form.Control>

              </InputGroup>
            </Col>
            <Col lg={3} md={4}>
              <Link to={`/addcollection`} className="btn btn-info btn-block"> নতুন <i className="fas fa-search"></i></Link>
            </Col>
          </Row>

          <Row>
            <Table className="custom-warring table-hover mt-5" striped>
              <thead >

                <tr>
                  <th>তারিখ </th>
                  <th>তালিকা নং  </th>
                  <th>প্রস্তুতকারী  </th>
                  <th>বিস্তারিত </th>
                </tr>
              </thead>
              <tbody>
                {this.renderCollection()}



              </tbody>
            </Table>



          </Row>



        </Container>





      </Col>


    )
  }

}
const mapStateToProps = state => ({
  int_collection: state.collection
});


export default withTranslation()(connect(mapStateToProps, {CollectionByMonth, intCollection })(Collection));