import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';
import { showToastNotification } from '../../common/showToastNotification';
import { removeLocalStorage } from '../../common/RemoveLocalStorage';
import { connect } from 'react-redux';
import { getLocalizedMessages } from '../../actions/localizedMessageActions';
import { getUserSettings } from '../../actions/userActions';

import { messageKeys } from '../../common/messageKeys';
import LocalizedMessage from '../../common/LocalizedMessage';


import { Row,Col ,Container } from 'react-bootstrap';




class Dashboard extends Component {
    componentDidMount() {
        //  window.history.pushState(null, null, '/')



        document.title = "Co oparative credit union management system | Home";
        this.props.getLocalizedMessages(messageKeys);

        this.props.getUserSettings();




    }

    componentWillReceiveProps(nextProps) {

        if (nextProps && nextProps.message && nextProps.message.key) {
      
            if (nextProps.message.key.dictionary && nextProps.message.key.dictionary) {
                localStorage.setItem('dictionary', JSON.stringify(nextProps.message.key.dictionary));
            }
        }


        const update = nextProps.location.state && nextProps.location.state.update;
        const save = nextProps.location.state && nextProps.location.state.save;

        if (update === true) {
            let message = localStorage.dictionary && JSON.parse(localStorage.getItem('dictionary'))['productConfiguration.notification.update'];
            showToastNotification(message);
        }

        if (save === true) {
            let message = localStorage.dictionary && JSON.parse(localStorage.getItem('dictionary'))['productConfiguration.notification.save'];
            showToastNotification(message);
        }
    }

    render() {
        return (
                <Col>
                    {localStorage.dictionary && <Header />}
                
                    <Container >
                        <div id="show-notification"></div>
                        <div className="row fullWidthCol mainContent">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 col-xl-4 d-flex align-items-center  justify-content-center ">
                                <div className="col-lg-12 col-md-5 col-12">
                                    <div className="row">
                                        <Link to='/lezar' style={{color: '#fff'}} className="col-sm-12 col-md-12 col-lg-12 col-12 equalHeight  hover_effect ">
                
                                        <div className="col-sm-12  text-center bg-lightvariant py-5 ">
                                            <img style={{height: '100px'}} src="assets/img/product-configaration.png" alt='Product Configurator Logo' className="img-fluid" />
                                            <h5 className="menu-upper-text pt-4">
                
                                                <LocalizedMessage messageKey="menu.lezar"/>
                                            </h5>
                                        </div>
                
                                        </Link>
                                    </div>
                                </div>
                            </div>
                
                
                
                            <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12  justify-content-center">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6 col-sm-12 col-xl-12 col-12 equalHeight hover_effect ">
                                        <Link to='/collection' style={{color: '#fff'}}>
                                        <div className="col-sm-12  text-center bg-lightblue  py-5 ">
                
                                            <img style={{height: '100px'}} src="assets/img/product-section.png" alt='Product Configurator Logo' className="img-fluid" />
                                            <h5 className="menu-upper-text pt-4">
                                                <LocalizedMessage messageKey="menu.credit" />
                                            </h5>
                                        </div>
                                        </Link>
                                    </div>
                
                
                
                
                
                
                                    <div className="col-lg-12 col-md-6 col-sm-12 col-xl-12 col-12 equalHeight  hover_effect">
                                        <Link to='/expense' style={{color: '#fff'}}>
                                        <div className="col-sm-12  text-center bg-lightdarkorange py-5">
                
                                            <img style={{height: '100px'}} src="assets/img/quote-Icon.png" alt='Product Configurator Logo' className="img-fluid" />
                                            <h5 className="menu-upper-text pt-4">
                                                <LocalizedMessage messageKey="menu.debit" />
                                            </h5>
                                        </div>
                                        </Link>
                                    </div>
                
                
                                </div>
                            </div>
                
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12 col-xl-4 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 col-md-5 col-12">
                                    <div className="row ">
                                        <Link to='/customer' style={{color: '#fff'}} className="col-lg-12 col-sm-6 col-md-12 equalHeight pl-0 pr-0 bg-lightorange hover_effect ">
                
                                        <div className="col-sm-12  text-center bg-lightorange py-5 pl-0 pr-0">
                                            <img src="assets/img/customer-management.png" alt='Product Configurator Logo' className="img-fluid" />
                
                                            <h5 className="menu-upper-text pt-4 pl-0 pr-0">
                                                <LocalizedMessage messageKey="menu.customer" />
                                            </h5>
                                        </div>
                
                                        </Link>
                                    </div>
                                </div>
                            </div>
                
                        </div>
                    </Container>
                </Col>
                )
    }
}

const mapStateToProps = state => ({
        message: state.message
    })


export default connect(mapStateToProps, {getLocalizedMessages, getUserSettings})(Dashboard);
